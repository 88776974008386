html {
  font-size: 100%;
}
body {
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
button:disabled {
  opacity: 0.5;
}
.ck.ck-editor__main > .ck-editor__editable p {
  line-height: 22px;
  margin-bottom: 10px;
}
.Login {
  margin-top: 10%;
}
.Login__title {
  margin: 0 0 1.5rem 0;
  text-align: center;
}
.Login__button-row {
  text-align: center;
}
.Login__login-button {
  width: 50%;
}
.Header__userInfo {
  color: #fff;
  float: right;
}
.Header__exit-link {
  margin: 0 0 0 15px;
}
@media screen and (max-width: 1024px) {
.Header {
  padding: 0 10px;
}
}
.Content {
  padding: 25px 50px 0 50px;
@media screen and (max-width: 1024px) {
}
}
.Content__container {
  background-color: #fff;
  padding: 24px;
  min-height: 280px;
}
@media screen and (max-width: 1024px) {
.Content {
  padding: 0;
}
.Content__container {
  padding: 15px 10px;
}
}
.Footer {
  text-align: center;
}
.fancybox__thumbs {
  display: none;
}
.Client__edit-link {
  margin-left: 5px;
}
.Client__field-name {
  font-weight: bold;
}
.Client__calendar-col {
  text-align: right;
}
.Client__calendar-icon {
  font-size: 1.3rem;
}
.Client__settings-button {
  margin-right: 0.5rem;
}
.Client__modal-div {
  margin-bottom: 1rem;
}
.Client__modal-div-title {
  margin-bottom: 0.2rem;
}
.Client__table-hidden-row {
  display: none;
}
.Client__table-new-row td {
  background-color: #fff200 !important;
}
.Client__table-static-row td {
  background-color: #fafafa;
}
.Client__table-emoji {
  font-size: 1.2rem;
}
.Client__table-emoji-single {
  font-size: 1.6rem;
}
.Client__undertextarea-button {
  margin-top: 0.5rem;
}
.Client__form-ul {
  padding-left: 15px;
  margin-bottom: 0;
}
.Anketa__steps-content {
  margin: 20px 0;
}
.Anketa__question {
  margin-bottom: 10px;
}
.FoodForm .ant-form-item-label {
  text-align: left;
  font-weight: bolder;
}
.FoodForm .ant-form-item-label > label {
  color: rgba(0,0,0,0.65);
}
.FoodForm .ant-form-item-label > label::after {
  display: none;
}
.Interview__header {
  font-size: 1.17rem;
  margin-top: 1rem;
}
.Interview__answer-block {
  margin-bottom: 1rem;
}
.Interview__question {
  margin-bottom: 10px;
}
.Interview__dropdown {
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  display: inline-block;
}
.Interview__form-save-button {
  margin-top: 20px;
}
.Weight__color-mark {
  display: inline-block;
  height: 16px;
  width: 25px;
  margin-right: 5px;
  margin-top: 6px;
}
.Weight__color-mark-green {
  background-color: #389e0d;
}
.Weight__color-mark-yellow {
  background-color: #ffa328;
}
.Weight__color-mark-red {
  background-color: #ff4d4f;
}
.Clients__add-button {
  margin-left: 15px;
}
.Clients__settings {
  margin: 1rem 0;
}
.Clients__settings-col {
  margin: 0 0 1rem 0;
}
.Clients__popover-snipet {
  max-width: 500px;
}
.Clients__duty-info {
  font-size: 1.3rem;
  margin: 0 0 0 7px;
  color: linkBlue;
}
.Clients__duty-info:hover {
  cursor: pointer;
}
.Clients__tabs {
  margin: 0 0 1.5rem 0;
}
.Clients__loader {
  margin: 2rem 0;
  text-align: center;
}
.Clients__calendar-col {
  text-align: right;
}
.Clients__calendar-icon {
  font-size: 1.3rem;
}
.Clients__table-row:hover {
  cursor: pointer;
}
.Clients__table-row-important {
  background-color: #ffe7ba;
}
.Clients__consistent-event {
  color: #d4b106;
  font-size: 1.2rem;
}
.Clients__agreed-event {
  color: #389e0d;
  font-size: 1.2rem;
}
.Clients__internal-event {
  color: #ff4d4f;
  font-size: 1.2rem;
}
.Clients__control-buttons {
  font-size: 1.2em;
}
.Clients__link {
  color: #1890ff;
}
.Clients__link:hover {
  color: #40a9ff;
  cursor: pointer;
}
.Clients__branch-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}
.Clients__table-cell:hover {
  cursor: pointer;
}
.Clients__search {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.Clients__weight-status {
  font-size: 1.2rem;
  margin-left: 5px;
}
.Clients__weight-status-green {
  color: #389e0d;
}
.Clients__weight-status-yellow {
  color: #ffa328;
}
.Clients__weight-status-red {
  color: #ff4d4f;
}
.MySchedule__card {
  margin-bottom: 15px;
}
.MySchedule__timespan {
  margin-bottom: 15px;
}
.Settings__block {
  margin-bottom: 0.75rem;
}
.Settings__block-title {
  margin-bottom: 5px;
}
.Settings__save-button {
  margin-top: 0.5rem;
}
